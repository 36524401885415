@use "../../styles/theme";

.cardSubContent {
  display: inline-block;
  width: 100%;
  margin-top: theme.space(3);
}

.cardSubContentHeader {
  margin-bottom: theme.space();
  font-size: theme.$font-size-base;
  text-transform: uppercase;
}
.cardSubContentHeaderSm {
  composes: cardSubContentHeader;
  font-size: theme.$font-size-sm;
}

.cardSubContentHeaderRight {
  display: inline-block;
  margin-left: theme.space(0.5);
  color: theme.$subheading-color;
  font-size: theme.$font-size-sm;
  vertical-align: middle;
}
.cardSubContentHeaderRightSm {
  composes: cardSubContentHeaderRight;
  text-transform: lowercase;
  vertical-align: baseline;
}

.cardSubContentBody {
  display: flex;
  justify-content: space-between;

  & > * {
    width: 150px;
  }
}

.cardSubContentItem {
  flex: 1 1 130px;
}
