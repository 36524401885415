@use "../../../styles/theme";

.cardInfo {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0 theme.space(1.5);
  text-align: center;
  background: rgba(0, 97, 217, 0.05);
  width: 20%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  body & > :global(.ant-card-body) {
    padding-top: 0;
  }
}

.cardInfoNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin: theme.space(3) 0 theme.space(1.5);
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;

  div {
    white-space: nowrap;
  }
}

.cardInfoNumberBackground {
  width: 64px;
  height: 64px;
  padding-top: theme.space(1);
  font-size: 32px;
  border-radius: 50%;
}

.cardInfoRate-high {
  composes: cardInfoNumberBackground;
  color: theme.$primary-color-contrast;
  background-color: theme.$color-green;
}

.cardInfoRate-middle {
  composes: cardInfoNumberBackground;
  background-color: theme.$color-yellow;
}

.cardInfoRate-low {
  composes: cardInfoNumberBackground;
  color: theme.$primary-color-contrast;
  background-color: theme.$color-red;
}

.cardInfoRate-margin {
  margin-left: 50px + theme.space();
}

.cardInfoSubRate-high {
  color: theme.$color-green;
}

.cardInfoSubRate-middle {
  color: theme.$color-yellow;
}

.cardInfoSubRate-low {
  color: theme.$color-red;
}

.cardInfoDescription {
  margin-top: 12px;
}

.cardInfoExtraRate {
  display: inline-block;
  align-items: center;
  margin-left: theme.space();
  font-size: 14px;
  align-self: baseline;
  text-align: left;
}

.high {
  composes: cardInfoExtraRate;
  color: theme.$success-color;
}

.middle {
  composes: cardInfoExtraRate;
  color: theme.$warning-color;
}

.low {
  composes: cardInfoExtraRate;
  color: theme.$error-color;
}
/*
.cardInfo {
  position: relative;
  width: 25%;
  height: 184px;
  margin: 0 theme.space(1.5);
  background: rgba(0, 97, 217, 0.05);
}

.cardInfoWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.cardInfoNumber {
  margin: auto;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}

.cardInfoNumberBackground {
  width: 64px;
  height: 64px;
  padding-top: theme.space(1);
  color: theme.$primary-color-contrast;
  background-color: theme.$color-green;
  border-radius: 50%;
}

.cardInfoDescription {
  margin-top: 12px;
}
*/
