@use "../../styles/theme";

.progressBar {
  margin: theme.space(0.5) 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  color: theme.$text-color-secondary;
  font-size: 14px;
}

.value {
  font-weight: 500;
}
