@use "../../styles/theme";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: theme.space() theme.space(4);
  color: theme.$primary-color-contrast;
  background-image: url("./header-image.jpg");
  background-size: cover;
  background-position-y: center;
}

.logoWrapper {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.logo {
  flex: 0 0 auto;
  margin-right: theme.space(1.5);
  height: 64px;
}

.title {
  flex: 1 1 auto;
}

.upperTitle {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: theme.space(0.5);
}

.lowerTitle {
  font-size: theme.$font-size-sm;
}

.filters {
  flex: 0 0 auto;

  .button {
    color: theme.$primary-color-contrast;
    background: transparent;
    border-color: theme.$primary-color-contrast;

    &:hover {
      color: lighten(theme.$primary-color, 25%);
      border-color: lighten(theme.$primary-color, 25%);
    }
  }
}
