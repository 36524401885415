@use "../../styles/theme";

.cardTop {
  display: flex;
  align-items: center;
  padding: 0 theme.space(4) theme.space(4);

  img {
    height: 40px;
  }

  h2 {
    margin: 0;
  }
}

.cardsWrapper {
  display: flex;
}

.logo {
  margin-right: theme.space(1.5);
}

.cardSubjectSummaryWrapper {
  padding: 0 theme.space(4) theme.space(4);
}
