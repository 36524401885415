@use "../../styles/theme";

.bar {
  position: relative;
  display: flex;
  width: 100%;
  height: 20px;
  background: rgba(theme.$color-blue, 0.05);

  & > :not(.value, .position) {
    height: 100%;
  }
}

.barTransparent {
  background: transparent;
}

.red {
  background-color: theme.$color-red;

  &.bgTransparent {
    background-color: transparent;
    border: 1px solid theme.$color-red;
  }
}

.green {
  background-color: theme.$color-green;

  &.bgTransparent {
    background-color: transparent;
    border: 1px solid theme.$color-green;
  }
}

.yellow {
  background-color: theme.$color-yellow;

  &.bgTransparent {
    background-color: transparent;
    border: 1px solid theme.$color-yellow;
  }
}

.orange {
  background-color: theme.$color-orange;

  &.bgTransparent {
    background-color: transparent;
    border: 1px solid theme.$color-orange;
  }
}

.blue {
  background-color: theme.$color-blue;

  &.bgTransparent {
    background-color: transparent;
    border: 1px solid theme.$color-blue;
  }
}

.disabled {
  opacity: 0.15;
}

.arrow {
  width: 100%;
  height: 100%;
  opacity: 0.8;

  &:not(.arrowTransparent) {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFdSURBVHgB7dpbTgMxEETRGjZOs3Eu6QlCCPFIMna5R/SV/G3V+bQsxvSsBV3uDQ4mxnVKhJEA2ekQRgNkp0KYAZCdBmEWQHYKhJkAWXmE2QBZaQQHQFYWwQWQlURwAmTlENwAWSmEFQBZGYRVAFkJhJUA2XKE1QDZUoQKANkyhCGPCoNagvAhQY0agUbYawQaYa8RaIS9RqAR9hqBRthrBBphrxE4iPCkx0M12uQu1alRyB09vkQhd/T4EoXc0eNLFHJHjy9RyB09vkQhd/T4EoXc8Y/HX+7UljdrfS/btoWMvc+OCv8DQua4/oso8UEiZI5P42EtQMgcX8bDOoCQOb4ZD2sAQub4YTz4AULm+GU8eAFC5vhjPPgAQua4YTx4AELmuHE8zAcImeOO8TAXIGSOO8fDPICQOR4YD3MAQuZ4cDyMBwiZ48B4GAsQMsfB8TAOILQgro85r0fOG6raw5XYkBF2AAAAAElFTkSuQmCC);
    background-size: contain;
  }
}

.bgTransparent {
  opacity: 0.8;
}

.arrowTransparent {
  mask: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFdSURBVHgB7dpbTgMxEETRGjZOs3Eu6QlCCPFIMna5R/SV/G3V+bQsxvSsBV3uDQ4mxnVKhJEA2ekQRgNkp0KYAZCdBmEWQHYKhJkAWXmE2QBZaQQHQFYWwQWQlURwAmTlENwAWSmEFQBZGYRVAFkJhJUA2XKE1QDZUoQKANkyhCGPCoNagvAhQY0agUbYawQaYa8RaIS9RqAR9hqBRthrBBphrxE4iPCkx0M12uQu1alRyB09vkQhd/T4EoXc0eNLFHJHjy9RyB09vkQhd/T4EoXc8Y/HX+7UljdrfS/btoWMvc+OCv8DQua4/oso8UEiZI5P42EtQMgcX8bDOoCQOb4ZD2sAQub4YTz4AULm+GU8eAFC5vhjPPgAQua4YTx4AELmuHE8zAcImeOO8TAXIGSOO8fDPICQOR4YD3MAQuZ4cDyMBwiZ48B4GAsQMsfB8TAOILQgro85r0fOG6raw5XYkBF2AAAAAElFTkSuQmCC);
  zoom: 0.31;
}

.value {
  position: absolute;
  margin-top: -9px;
  padding: 6px;
  color: white;
  font-weight: bold;
  font-size: theme.$font-size-base;
  border: 3px solid white;
  border-radius: 100%;
}

.left {
  transform: scaleX(-1);
}

.right {
  transform: scaleX(1);
}

.position {
  position: absolute;
  bottom: calc(100% + 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-left: -13px;
  color: theme.$primary-color-contrast;
  font-weight: 600;
  font-size: theme.$font-size-sm;
  background-color: #000;
  border-radius: theme.border-radius();

  &:after {
    position: absolute;
    top: 100%;
    left: 50%;
    display: block;
    width: 12px;
    height: 5px;
    margin-left: -6px;
    background: url('data:image/svg+xml,<svg width="12" height="5" viewBox="0 0 12 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0.0292969H12L10.7149 0.832472C8.91792 1.9556 7.3238 3.37455 6 5.0293C4.6762 3.37455 3.08208 1.9556 1.28508 0.832472L0 0.0292969Z" fill="black"/></svg>');
    content: "";
  }
}
