@use "../../../styles/theme" as theme;

$width: 264px;
$height: 40px;

.field {
  width: $width;
  margin-left: 10px;

  &:global(.ant-select) {
    :global {
      .ant-select-selector {
        color: theme.$primary-color-contrast;
        background-color: transparent;
      }
    }
  }

  &:global(.ant-select-single) {
    :global {
      .ant-select-selector {
        height: $height;

        .ant-select-selection-search-input {
          height: $height;
          line-height: $height;
        }

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: $height;
        }
      }
    }
  }

  :global {
    .ant-select-arrow {
      color: theme.$primary-color-contrast;
    }
  }
}
