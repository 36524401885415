@use "../styles/theme";

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.content {
  flex: 1 1 auto;
  background-color: theme.$content-background-color;
}
