@use "../../styles/theme";

.view {
  padding: theme.space(2) theme.space(3);
  text-align: center;
  border-top: 1px solid theme.$border-color-base;

  &_title {
    margin-bottom: theme.space(2);
  }

  .circles {
    display: flex;
    justify-content: space-around;
    margin-bottom: theme.space(3);

    .circle {
      width: 40px;
      height: 40px;
      margin: 0 auto theme.space(0.5);
      border: 2px solid rgba(theme.$error-color-text, 0.29);
      border-radius: 50%;

      &_orange {
        border-color: rgba(theme.$warning-color-text, 0.25);
      }

      &_green {
        border-color: rgba(theme.$success-color-text, 0.25);
      }

      &__num {
        padding: theme.space(0.75);
        color: theme.$error-color-text;
        font-weight: bold;
        font-size: 20px;

        &_orange {
          color: theme.$warning-color-text;
        }

        &_green {
          color: theme.$success-color;
        }
      }

      &__description {
        color: theme.$text-color-secondary;
        font-size: theme.$font-size-sm;
      }
    }
  }
}
.subjectCount {
  margin-left: theme.space();
}
