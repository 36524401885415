@use "../../styles/theme";

.card {
  width: 50%;
  margin: 0 theme.space(4) theme.space(4) theme.space(2);
}

%content {
  height: 600px;
  margin: 0 theme.space(-3);
  padding: 0 theme.space(3);
  overflow-y: auto;
}

.cardContent {
  @extend %content;
}

.cardEmpty {
  composes: card;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.cardEmptyContent {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: center;

  @extend %content;
}

.title {
  padding-top: theme.space(2.5);
}

.progressBarWrapper {
  margin: theme.space(7.5) 0;
}
