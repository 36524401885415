@use "../../styles/theme";

.textValue {
  color: theme.$text-color;
  font-weight: 600;
  white-space: nowrap;
}

.info {
  display: inline-block;
  align-items: center;
  margin-left: theme.space();
  font-size: 14px;
  vertical-align: top;

  svg {
    margin-bottom: -3px;
  }
}

.small {
  composes: textValue;
  font-size: theme.$font-size-lg;
}

.big {
  composes: textValue;
  font-size: theme.$font-size-lg + 6px;
}

.high {
  composes: info;
  color: theme.$success-color;
}

.middle {
  composes: info;
  color: theme.$warning-color;
}

.low {
  composes: info;
  color: theme.$error-color;
}

.footerText {
  color: theme.$text-color-secondary;
  font-size: theme.$font-size-base;
  line-height: theme.$line-height-base;
  white-space: pre-wrap;
}

.footerTextLight {
  composes: footerText;
  font-weight: 100;
}

.iconUp {
  fill: theme.$success-color;
}

.iconDown {
  transform: rotateZ(180deg) translate(-23px, -25px);
  fill: theme.$error-color;
}
