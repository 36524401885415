@use "../../styles/theme";

.cards {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
  gap: theme.space(4);
  min-height: 100%;
  padding: theme.space(4);
}

.card {
  display: flex;
  align-items: stretch;
  cursor: pointer;
}

.date {
  border-bottom: 1px solid #d9d9de;
}

.spin {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}
