@use "../../styles/theme";

.search {
  flex: 4;
  width: 100%;
  max-width: 344px;
  margin-right: theme.space(3);

  &:hover, &:focus {
    border-color: theme.$primary-color !important;
    box-shadow: none;
  }
}

.cardTop {
  padding: 0 theme.space(4) theme.space(4);
}

.cardRow {
  display: flex;
  gap: theme.space(4);
  align-items: stretch;
  justify-content: space-between;

  & > * {
    flex: 1;
  }
}

%textCircleIndicatorText {
  display: inline-block;
  margin-right: 18px;
  color: theme.$text-color;
  font-size: theme.$font-size-base;
}

%textCircle {
  display: inline-block;
  width: theme.space(1.5);
  height: theme.space(1.5);
  margin-right: 10px;
  border-radius: 100%;
  content: "";
}

.textCircleIndicator {
  &High {
    @extend %textCircleIndicatorText;

    &::before {
      background: theme.$success-color;
      @extend %textCircle;
    }
  }

  &Medium {
    @extend %textCircleIndicatorText;

    &::before {
      background: theme.$warning-color;
      @extend %textCircle;
    }
  }

  &Low {
    padding-left: 30px;
    @extend %textCircleIndicatorText;

    &::before {
      background: theme.$error-color;
      @extend %textCircle;
    }
  }
}

.card2 {
  display: flex;
  flex: 2;
  flex-direction: column;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  a {
    color: theme.$link-color;

    &:hover {
      color: theme.$link-color-hover;
    }
  }

  :global {
    .ant-card-body {
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      align-items: flex-start;

      .ant-table-wrapper {
        flex: 1 1 auto;
        width: 100%;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;

            .ant-table-empty {
              height: 100%;

              .ant-table-container {
                height: 100%;

                .ant-table-content {
                  height: 100%;

                  & > table {
                    min-height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-table-cell {
      padding: 20px 6px !important;
    }

    .ant-table-cell {
      background: white;

      &::before {
        width: 0 !important;
      }
    }

    table {
      padding-bottom: 26px;
      overflow: scroll;
    }

    .ant-modal-body {
      max-height: 70vh;
      overflow: scroll;
    }
  }
}

body .card3 {
  border-radius: 0;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global {
    .ant-table-cell {
      padding: theme.space();
      background: white;

      &::before {
        height: 100% !important;
      }
    }

    table {
      border: 1px solid rgba(0, 0, 0, 0.06);

      a {
        color: theme.$primary-color;

        &:hover {
          color: #cb42a3;
        }
      }
    }

    thead :last-child > th {
      padding: theme.space(0.25) !important;
      text-align: center;
    }

    .ant-table-thead tr {
      .ant-table-cell {
        color: theme.$text-color-secondary;
        font-weight: 500;
      }
    }

    .ant-table-row .ant-table-cell {
      &:nth-child(1n + 3) {
        text-align: right;
      }
    }

    .ant-table-row:nth-child(2n + 3) td {
      background-color: #f2f7fd; //rgba(theme.$primary-color, 0.05);
    }
  }

  td.cellColoredWrapper,
  th.cellColoredWrapper {
    padding: 0;
  }
}

th.colMeasure,
th.colNum {
  font-size: theme.$font-size-sm;
}

.cellColored {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: theme.space();

  & > div {
    flex: 1 1 100%;
  }
}

.cellGreen {
  composes: cellColored;
  background-color: rgba(theme.$success-color, 0.15);
}
.cellYellow {
  composes: cellColored;
  background-color: rgba(theme.$warning-color, 0.15);
}
.cellRed {
  composes: cellColored;
  background-color: rgba(theme.$error-color-text, 0.15);
}

.totalRow {
  font-weight: bold;
}

.additionalLightsSelector {
  margin: 0 theme.space(2);
  font-size: theme.space(2);
  color: theme.$primary-color;
  
  &:hover {
    color: theme.$primary-color;
  }
}

.additionalLightsSelector {
  margin: 0 theme.space(2);
  font-size: theme.space(2);
}

.helperText {
  color: theme.$subheading-color;
  font-size: theme.$font-size-sm;
  text-transform: uppercase;
}

.downloadBtn {
  margin: 0 theme.space(1.5);
  height: auto;

  &:focus, &:hover, &:active {
    color: theme.$primary-color;
    border-color: theme.$primary-color;
  }
}
