// Данные переменные ничего не переопределяют, они только дублируют те, что определены в craco.config.js
// Без будлирования переменные не доступны в SASS, т.к. Antd использует LESS
// TODO: подумать, как избавиться от дублирования

$color-blue: #28718a;
$color-green: #5ccc65;
$color-yellow: #f2c141;
$color-orange: #f18f34;
$color-red: #e25353;

$font-family: GolosText;
$primary-color: $color-blue; // primary color for all components
$primary-color-contrast: #fff; // contract color for the primary one
$link-color: $color-blue; // link color
$link-color-hover: #cb42a3; // link color hover
$success-color: $color-green; // success state color
$success-color-text: #47b250;
$warning-color: $color-yellow; // warning state color
$warning-color-text: #ff9500;
$error-color: $color-red; // error state color
$error-color-text: #d51a1a;
$font-size-base: 16px; // major text font size
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;
$line-height-base: 1.25;
$heading-color: #000; // heading text color
$subheading-color: #a7a7ab; // heading text color
$text-color: #000; // major text color
$text-color-secondary: #76767a; // secondary text color
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 4px; // major border radius
$border-color-base: #d9d9de; // major border color
$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

// LINK

/*$link-color: $primary-color;
$link-hover-color: color(~`colorPalette("${link-color}", 5) `);
$link-active-color: color(~`colorPalette("${link-color}", 7) `);
$link-decoration: none;
$link-hover-decoration: none;
$link-focus-decoration: none;
$link-focus-outline: 0;*/

// custom variables
$content-background-color: #e5e5e5;

@function space($size: 1) {
  @return $size * 8px;
}

@function border-radius($k: 1) {
  @return $k * $border-radius-base;
}
