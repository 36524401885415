@use "./styles/theme";
@import "~antd/dist/antd.css";
@import "./styles/fonts/golos.css";

body {
  margin: 0;
  font-family: GolosText, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  .ant-radio-group {
    padding: 3px;
    border: 1px solid theme.$primary-color;
    border-radius: 10px;

    .ant-radio-button-wrapper {
      height: auto;
      padding: 12px 16px;
      color: theme.$primary-color;
      font-weight: 500;
      font-size: 12px;
      line-height: inherit;
      border: 0;
    }

    .ant-radio-button-wrapper-checked {
      color: white;
      background-color: theme.$primary-color !important;
      border-radius: 8px;
    }

    .ant-radio-button-wrapper::before {
      width: 0 !important;
    }
  }

  .ant-card {
    border-radius: theme.border-radius(3);

    .ant-card-head {
      border-bottom: 0;
    }

    .ant-card-body {
      padding-top: 4px;
    }

    .cardExtraLinks {
      display: flex;
      margin-top: theme.space(2);
      text-transform: uppercase;
      font-size: theme.$font-size-sm;
      justify-content: space-between;

      & > * {
        width: 150px;
      }

      div {
        flex: 1 1 130px;
      }

      a {
        color: theme.$link-color;

        &:hover {
          text-decoration: underline;
          color: theme.$link-color-hover;
        }
      }
    }
  }

  .ant-btn-primary {
    background-color: theme.$primary-color;
    border-color: theme.$primary-color;

    &:hover, &:visited, &:focus, &:active {
      background-color: theme.$primary-color;
      border-color: theme.$primary-color;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
