@use "../../styles/theme";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;

  > :global(.ant-card) {
    min-height: 100%;
  }

  > :global(.ant-card-body) {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    justify-content: space-between;
    min-height: 200px;
    padding: 0;

    &:before,
    &:after {
      display: none;
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rateLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 172px;
  border-top: 1px solid theme.$border-color-base;
}
