@use "../../styles/theme";

.card {
  width: 50%;
  margin: 0 theme.space(2) theme.space(4) theme.space(4);
}

.cardEmpty {
  composes: card;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.cardEmptyContent {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: center;
}

.title {
  padding-top: theme.space(2.5);
}

.titleDateWrapper {
  display: flex;
}

.titleDate {
  width: 50%;
  margin-top: theme.space(2);
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.subTitle {
  color: theme.$text-color-secondary;
  margin: theme.space(3) 0;
  font-size: theme.$font-size-base;
  line-height: 20px;
}

.progressBarWrapper {
  display: flex;
}

.progressBar {
  width: 50%;
  padding-right: theme.space(3.5);
}

.progressBarTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
