@use "../../styles/theme";

.cardHeaderRightFullSize {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-weight: 600;
  font-size: 24px;
}

.cardHeaderTitle {
  white-space: normal;
}

.helperText {
  color: theme.$subheading-color;
  font-size: theme.$font-size-sm;
  text-transform: uppercase;
}
.subTitle {
  margin-top: theme.space();
  font-size: theme.$font-size-base;
  text-transform: uppercase;
}
.cardHeaderSm {
  composes: cardHeader;
  font-size: 20px;
}
