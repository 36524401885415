/* Generated by ParaType (http://www.paratype.com)*/

/* Font Golos Text: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
  font-weight: normal;
  font-family: "GolosText";
  font-style: normal;
  src: url("Golos-Text_Regular.woff2") format("woff2"),
    url("Golos-Text_Regular.woff") format("woff");
}
